// applying overlay on hover for cards
.cards{
    .card{
        .thumbnail{
            &:hover{
                .overlay{
                    opacity: 1;
                }
            }
            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
                opacity: 0; /* Initially hidden */
                transition: opacity 0.3s ease; /* Smooth transition effect */
                border-radius: 8px 8px 0 0;
            }
            .playBtn {
                z-index: 9;
            }
            img {
                width: 100%;
                height: 100%;
                display: block;
            }
            border-bottom: 1px solid rgb(237 221 221 / 20%);
        }
        .description{
            flex-grow: 1;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            .videoCaption, .videoCatagory  {
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
            }
        }
    }
}
// catagory active class
.catagory, .subCatagory
    {
        &.active{
            background-color: #0057B8;
            color: #fff;
        }
    }
// authors img
.authorsSection{
    .authorPicHolder{
            img{
                object-fit: cover;
                height: 100%;
                margin: auto;
                width: 100%;
            }
        width: 180px;
        height: 190px;
        background-color: #ebf0f5;
        border-radius: 8px;
    }
}
.authorsListGrid{
    .authorPicHolder{
        img{
            object-fit: cover;
            height: 100%;
            margin: auto;
            width: 100%;
        }
        height: 260px;
        background-color: #ebf0f5;
        border-radius: 8px;
    }
}


// applying background images for peers search list and ophthalmicPrecision sections
.pearsSearch{
    background: #E5F1FA url('../../images/peersListBg.svg') no-repeat right;
    background-size: auto;
}
.ophthalmicPrecision{
    .carousel{
        .control-dots{
            align-items: end !important;
            bottom: 24px !important;
            margin: 0 !important;
        }
    } 
}