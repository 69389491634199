.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.75);
}

.modal {
    position: fixed;
    height: 100%;
    width: 100%;
    /* border-radius: 14px; */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index:999;
    animation: slide-down 300ms ease-out forwards;
    overflow: auto;
}

@media (min-width: 768px) {
    .modal {
  
    }
}

@keyframes slide-down {
    from {
        opacity: 0;
        transform: translateY(-3rem);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
