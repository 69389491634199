.termsAndConditionsHlder{
    h2{
        color: #333;
        font-family: barlowBold;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -0.25px;
        margin-bottom: 24px;
        max-width: 80%;
    }
    h3{
        margin-bottom: 24px;
    }
    p{
        margin-bottom: 20px;
        color: #333;
        font-weight: normal;
        font-size: 18px;
        max-width: 80%;
    }
}