.userProfile {
    ul{
        li{
            &:hover{
                span{
                    color: #0057B8;
                    svg{
                        path{
                            fill:#0057B8
                        }
                    }
                }
            }
            &.support{
                &:hover{
                    span{
                        svg{
                            rect, circle{
                                fill: #0057B8;
                            }
                            path{
                                fill:transparent;
                                stroke: #0057B8;
                            }
                        }
                    }
                }
            }
            &.preferences{
                &:hover{
                    span{
                        svg{
                            rect, circle{
                                fill: #0057B8;
                            }
                            path{
                                fill:transparent;
                                stroke: #0057B8;
                            }
                        }
                    }
                }
            }
            &.active{
                span{
                    color: #0057B8;
                    svg{
                        path{
                            fill:#0057B8
                        }
                    }
                }
                &.support{
                    span{
                        svg{
                            rect, circle{
                                fill: #0057B8;
                            }
                            path{
                                fill:transparent;
                                stroke: #0057B8;
                            }
                        }
                    }
                }
                &.preferences{
                    span{
                        svg{
                            rect, circle{
                                fill: #0057B8;
                            }
                            path{
                                fill:transparent;
                                stroke: #0057B8;
                            }
                        }
                    }
                }
            }
        }
    }
}