/* Style for the popup */
.popup {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 100; /* Ensure popup is above overlay */
}
  
  /* Style for the close button */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.overlay-popup {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent black overlay */
    z-index: 50; /* Ensure overlay is above other elements */
}
  /* Additional styles for the popup content */
.popup-content {
    color: #333;
}