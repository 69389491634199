@media only screen and (max-width:355px) {
    .authorsListSection{
        .authorsListGrid{
            .authorPicHolder.author{
                height: auto !important;
            }
        }
    }
}

@media only screen and (max-width:380px) {
    .card{
        .thumbnail{
            .actionsHolder{
                svg{
                    height: 14px;
                    width: 16px;
                }
                gap: 8px;
            }
        }
    }
    .searchResutsVideos{
        .cards{
            .card{
                .thumbnail {
                    height: auto;
                }
            }
        }
    }
    .authorsListSection{
        .authorsListGrid{
            .authorPicHolder{
                height: 200px !important;
            }
        }
    }
}
@media only screen and (max-width: 420px) {
    .exploreBtn,.talkToUs,a.button,.continueBtn,section.talktoUs .btn  {
    width: 100%;
    min-width:100%;
    }
    .videosList{
        .cards{
            .card {
                width: calc(90% - 40px) ! important;
                .thumbnail{
                    height: 160px;
                }
            }
        }
    }
    .pearsSearch{
        background-size: contain;
    }
    .loginSection{
        width: 100% !important;
    }
    .loginFormHolder{
        .loginSection{
            max-width: 100% !important;
        }
    } 
    .notificationHolder{
        position: fixed;
        top: 0;
        margin-top: 0;
        max-height: 100vh;
        width: 100%;
        border-radius: 0;
    }
    .searchResutsVideos{
        .cards{
            display: grid !important;
            grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)) !important;
        }
    }
    .videoSlider{
        .carousel{
            ul.control-dots{
                margin-top: 0;
                gap: 16px;
                bottom: 0 !important;
                li{
                    min-height: 12px;
                    min-width: 12px;
                }
            }
        }
        div.vjs-control-bar{
            bottom: 0px !important;
        }
    }
    .ophthalmicPrecision{
        .carousel{
            ul.control-dots{
                gap: 16px;
                li{
                    min-height: 12px;
                    min-width: 12px;
                }
            }
        }
    }
    .authorsListGrid{
        .authorPicHolder{
            img{
                object-fit: cover;
                height: 100%;
                margin: auto;
                width: 100%;
            }
        }
    }
    .stepperBar{
        > div:first-child{
            > div{
                gap: 12px !important;
            }
        }
    }
}

@media only screen and (max-width: 430px) {
    .cropImageHolder{
        canvas{
            width: 100% !important;
            height: auto !important;
        }
    }
}

@media only screen and (min-width:420px) and (max-width:575px){
    .searchResutsVideos{
        .cards{
            display: grid !important;
            grid-template-columns: repeat(auto-fill, minmax(230px, 1fr)) !important;
        }
    }
}

@media only screen and (max-width: 550px) {
    .authorAndActions{
        flex-wrap: wrap !important;
        flex-direction: column-reverse;
        align-items: self-start;
        > div{width: 100%;}
        .channelInfo{
            padding: 16px 0;
            border-top:1px solid #CECECE;
            border-bottom:1px solid #CECECE
        }
    }
    #profile{
        .responsiveGrid{
            display: grid !important;
            grid-template-columns: 1fr !important;
        }
    }
}

@media only screen and (min-width: 630px) {
    .videoSlider {
        .vjs-control-bar{
            bottom: 48px !important;
        }
    }
}
@media only screen and (max-width: 630px) {
    .authorsListGrid{
        .authorPicHolder{
            img{
                object-fit: cover;
                height: 100%;
                margin: auto;
                width: 100%;
            }
            height: 220px;
        }
    }
}
@media only screen and (max-width:640px){
    .footerContentHolder,.termsAndConditionsHlder{
        > div{
            &:first-child{
                font-size: 18px !important;
                margin-bottom: 8px !important;
            }
        }
        h2{
            font-size: 20px !important;
            line-height: 28px !important;
            letter-spacing: -0.25px;
            max-width: 100% !important;
        }
        p{
            margin-bottom: 16px !important;
            font-size: 16px !important;
            max-width: 100% !important;
        }
    }
    .carousel .slider-wrapper {
        > ul{
            
            li{
                > div{
                    
                    img{
                        
                        object-fit: cover !important;
                    }
                }
            }
        }
    }
    .sliders{
        &::after{
            background-size: cover !important;
        }
    }
    .loginFormHolder, .thanksMsgHolder, .successMsgBgHolder, .registerBgHolder, .contactUsFormBgHolder {
        .loginSection{
            padding: 40px !important;
        }
    }
}
@media only screen and (min-width: 768px) {
    .videosList{
        .cards{
            .card {
                width: calc(65% - 40px) ! important;
                max-width: 372px;
                .thumbnail{
                    height: 206px;
                }
            }
        }
    }
}
@media only screen and (min-width: 768px) {
    .videosList{
        .cards{
            .card {
                width: calc(65% - 40px) ! important;
                max-width: 372px;
                .thumbnail{
                    height: 206px;
                }
            }
        }
    }
}
@media only screen and (max-width:767px){
    .ophthalmicPrecision{
        background-size: cover;
    }
    .pearsSearch{
        background-size: contain;
    }
    .videoSlider{
        .carousel{
            .control-dots{
                bottom: 10px !important;
            }
        }
    }
    .videoSlider{
        .vjs-control-bar{
            bottom: 10px !important;
        }
    }
    .authorsSection{
        .authorPicHolder{
                img{
                    object-fit: cover;
                    height: 100%;
                    margin: auto;
                }
            width: 140px;
            height: 150px;
        }
    }
}
@media only screen and (min-width:576px) and (max-width:767px){
    .searchResutsVideos{
        .cards{
            display: grid !important;
            grid-template-columns: repeat(auto-fill, minmax(260px, 1fr)) !important;
        }
    }
}

@media only screen and (min-width:768px) and (max-width:855px){
    .searchResutsVideos{
        .cards{
            display: grid !important;
            grid-template-columns: repeat(auto-fill, minmax(310px, 1fr)) !important;
        }
    }
}
@media only screen and (min-width: 1023px) {
    main{
        section.section{
            &:nth-child(2){
                .VideosSection {
                    padding-top: 56px;
                }
            }
        }
    }
    .footerContentHolder{
        > {
            *{
                max-width: 80%;
            }
            .parentDisable{
                max-width: 100% !important;
            }
        }
    }
}
@media only screen and (max-width: 1023px) {
    main{
        section.section{
            &:nth-child(2){
                .VideosSection {
                    padding-top: 40px;
                }
            }
        }
    }
    .responsiveGrid{
        display: grid !important;
        grid-template-columns: repeat(auto-fill, minmax(47%, 1fr)) !important
    }
    .videoSlider, .ophthalmicPrecision {
        .carousel{
            .control-dots{
                left: 20px;
            }
        }
    }
    .footerContentHolder, .termsAndConditionsHlder,
    .exploreVideosSection .columnTwo,
    .authorColumns .authorInformation  {
        ul , ol{
            margin-left: 20px;
            li{
                padding-left: 8px;
            }
        }
    }
    .footerContentHolder{
        > {
            *{
                max-width: 100%;
            }
        }
    }
}
@media only screen and (min-width:1024px) and (max-width:1241px){
    .searchResutsVideos{
        .cards{
            display: grid !important;
            grid-template-columns: repeat(auto-fill, minmax(310px, 1fr)) !important;
        }
    }
}
@media only screen and (min-width:1242px) and (max-width:1323px){
    .searchResutsVideos{
        .cards{
            display: grid !important;
            grid-template-columns: repeat(auto-fill, minmax(320px, 1fr)) !important;
        }
    }
}


// authorListGrid all here
@media only screen and (min-width:1024px){
    .authorsListGrid{
        display: grid !important;
        grid-template-columns: repeat(auto-fill, minmax(210px, 1fr)) !important;
    }
}
@media only screen and (min-width:630px) and (max-width:1024px){
    .authorsListGrid{
        display: grid !important;
        grid-template-columns: repeat(auto-fill, minmax(185px, 1fr)) !important;
    }
    .ophthalmicPrecision{
        .sliders{
            &.contentOne,
            &.contentTwo,
            &.contentThree,
            &.contentFour{
                &::after{
                    width: 150% !important;
                }
            }
        }
    }
}
@media only screen and (min-width: 320px) {
    .authorsListGrid{
        display: grid !important;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
}

// specialization section background image
@media only screen and (max-width:1024px){
    .specializationSection{
        ::after{
            display: none;
        }
    }
    //catagory contrls left right arrow blue css
    .catagoryWrapper {
        position: relative;
        &::before{
            content: '';
            position: absolute;
            height: 32px;
            width: 32px;
            background: linear-gradient(to right, #fff 10%, rgba(255, 255, 255, 0) 80%);
            left: 0;
        }
        &::after{
            content: '';
            position: absolute;
            height: 32px;
            width: 32px;
            background: linear-gradient(to left, #fff 10%, rgba(255, 255, 255, 0) 80%);
            right: -1px;
        }
    }
}