.loginFormHolder, .thanksMsgHolder, .successMsgBgHolder, .registerBgHolder, .contactUsFormBgHolder, .resetPasswordBg, .userActivationBg, .succesMsgHolderBg, .gdprConsentHolderBg, .specializationSection {
    background-image: url(../../images/modalBg.svg);
    background-size: cover;
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 140px;
    .loginSection{
        padding:40px 60px;
        position: static;
        z-index: 99;
    }
}