div.notification-detail-holder{
    &.detailsPage{
        div.notificationHolder{
            position: static;
            height: 100%;
            width: 100% !important;
            overflow: auto;
            box-shadow: none;
            .notificationHeader {
                display: none;
            }
            .notificationBody {
                margin-top: 0;
                .notificationItem{
                    &:hover{
                        background-color: #ebf0f5;
                    }
                    &.active{
                        border-right: 4px solid #7DA1C4 ;
                        background-color: #ebf0f5;
                    }
                    .updateMsgIcon{
                        padding-left: 16px;
                    }
                    .msg{
                        padding-right: 16px;
                    }
                    // padding: 0 16px;
                    .unReadMsg {
                        padding-bottom: 12px;
                    }
                }
            }
            > div{
                padding: 0;
            }
        }
    }
}
.notificationHolder{
    .notificationItem{
        &:last-child{
            .msg{
                border-bottom: 0;
            }
        }
        &:first-child{
            .msg{
                border-top: 1px solid #E4E8F0;
            }
        }
    }
}