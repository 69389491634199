@mixin backgroundPropery {
    content: '';
    background-repeat: no-repeat;
    background-position: right center;
    background-size:50%;
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: -1;
}
.ophthalmicPrecision{
    *{
        height: 100%;
        width: 100%;
    }
    position: relative;
    padding: 0;
    .sliders{
        display: flex;
        align-items: center;
        &.contentOne{
            &::after{
                @include backgroundPropery;
                background-image: url("../../images/Slider/one.svg");
            }
        }
        &.contentTwo{
            &::after{
                @include backgroundPropery;
                background-image: url("../../images/Slider/two.svg");
            }
        }
        &.contentThree{
            &::after{
                @include backgroundPropery;
                background-image: url("../../images/Slider/three.svg");
            }
        }
        &.contentFour{
            &::after{
                @include backgroundPropery;
                background-image: url("../../images/Slider/four.svg");
                
            }
        }
    }
}