.stepperBar{
   > div:first-child{
       > div{
           display: flex !important;
           width: 100% !important;
           gap: 24px;
           justify-content: center;
           max-width: 70%;
           > div{
               width: 33.33% !important;
                > div{
                   width: 100% !important;
                   height: 12px !important;
                   border-radius: 50px !important;
                   background-color: #717375;
                   position: relative;
                   cursor: pointer;
                   span, a{
                       position: absolute;
                       inset: 0;
                       z-index: 50;
                       opacity: 0;
                   }
                   ~ div{
                       display: none !important;
                   }
                   &.active{
                       background-color: #0057B8 !important;
                   }
               }
           }
       }
   }
}