.footerContentHolder{
        > div{
            &:first-child{
                font-family: barlowBold;
                font-size: 28px;
                line-height: 36px;
                letter-spacing: -0.25px;
                margin-bottom: 24px;
            }
            &:nth-child(2){
                h2 + p{
                    font-size: 20px;
                    line-height: 28px;
                    letter-spacing: -0.25px;
                }
            }
        }
        :where(h1,h2,h3,h4,h5,h6){
            margin-bottom: 20px;
            font-family: barlowMedium;
            max-width: 80%;
            color: #333;
        }
        h2{
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.25px;
        }
        h3{
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.25px;
        }
        p{
            margin-bottom: 16px;
            color: #333;
            font-weight: normal;
            font-size: 16px;
        }
}