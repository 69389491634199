.videosHolder{
    > *{
        height: 100%;
    }
    .carousel.carousel-slider,
    .carousel .slider-wrapper {
        height: 100%;
        > ul{
            height: 100%;
            li{
                > div{
                    height: 100%;
                    img{
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                    > div{
                        height: 100%;
                        padding-top: 0;
                    }
                }
                // .video-js button{
                //     margin-top: 4px !important;
                // }
                .imageOverlay{
                    &::before{
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
                        // opacity: 0.2;
                        transition: opacity 0.3s ease; /* Smooth transition effect */
                        border-radius: 8px 8px 0 0;
                    }
                }
            }
        }
        .controlsHide{
            button{
                &.vjs-mute-control,
                &.vjs-play-control{
                    display: none !important;
                }
            }
        }
    }
    .carousel .control-dots{
        height: auto !important;
    }
    .vjs-limit-overlay{
        z-index: 99 !important;
    }
    
    span{
        a#clickHereLink{
            white-space: pre-wrap;
        }
    }
    
}
// banner videos 
.bannerVideos{
    .videosHolder{
        video{
            position: static !important;
        }
    }
}
.exploreVideosSection{
    .columnOne{
        > div{
            height: 100%;
        }
        .video-js{
            height: 100% !important;
            padding-top: 0;
        }
    }
} 
//  default video css
video::-webkit-media-controls {
    display: none;
}

.video-js .vjs-auto-mute {
  display: none !important;
}