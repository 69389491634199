/* roboto-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "robotoLight";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/roboto/roboto-v30-latin-300.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "robotoNormal";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/roboto/roboto-v30-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "robotoMedium";
  font-style: normal;
  font-weight: 500;
  src: url("../../fonts/roboto/roboto-v30-latin-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "robotoBold";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/roboto/roboto-v30-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "robotoExtrabold";
  font-style: normal;
  font-weight: 900;
  src: url("../../fonts/roboto/roboto-v30-latin-900.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* barlow-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "barlowNormal";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/barlow/barlow-v12-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* barlow-medium - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "barlowMedium";
  font-style: normal;
  font-weight: 500;
  src: url("../../fonts/barlow/barlow-v12-latin-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* barlow-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "barlowBold";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/barlow/barlow-v12-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #BDCDD6;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
  cursor: pointer;
}

.cards::-webkit-scrollbar,
.catagoryHolder::-webkit-scrollbar,
.catagoryWrapper::-webkit-scrollbar,
.authors::-webkit-scrollbar,
.subCatagoryHolder::-webkit-scrollbar,
.formWrapper::-webkit-scrollbar {
  display: none;
}

.cards,
.catagoryHolder,
.authors,
.catagoryWrapper,
.authors,
.subCatagoryHolder,
.formWrapper {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

@supports (-moz-appearance: none) {
  * {
    scrollbar-width: thin; /* "thin" or "auto" */
  }
}
body {
  font-family: robotoNormal;
  font-weight: normal;
}

.font-light {
  font-family: robotoLight;
}

.font-normal {
  font-family: robotoNormal;
}

.font-medium {
  font-family: robotoMedium;
}

.font-bold {
  font-family: robotoBold;
}

.font-extrabold {
  font-family: robotoExtrabold;
}

.barlowNormal {
  font-family: barlowNormal;
}

.barlowBold {
  font-family: barlowBold;
}

.barlowMedium {
  font-family: barlowMedium;
}

input:focus, textarea:focus {
  outline: none;
  box-shadow: none;
}

textarea {
  resize: none;
}

.btn:hover {
  background-color: #034b9b;
}

.disabled {
  opacity: 0.3;
  cursor: none;
  pointer-events: none;
}

.videosHolder video {
  background-color: #006FBA;
}

a {
  color: #0057B8;
}

.supportFormHolder video {
  clip-path: inset(1px 1px);
}

.doctorsName > span:last-child span.pipe {
  display: none;
}

.cards .card .thumbnail {
  border-bottom: 1px solid rgba(237, 221, 221, 0.2);
}
.cards .card .thumbnail:hover .overlay {
  opacity: 1;
}
.cards .card .thumbnail .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth transition effect */
  border-radius: 8px 8px 0 0;
}
.cards .card .thumbnail .playBtn {
  z-index: 9;
}
.cards .card .thumbnail img {
  width: 100%;
  height: 100%;
  display: block;
}
.cards .card .description {
  flex-grow: 1;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.cards .card .description .videoCaption, .cards .card .description .videoCatagory {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.catagory.active, .subCatagory.active {
  background-color: #0057B8;
  color: #fff;
}

.authorsSection .authorPicHolder {
  width: 180px;
  height: 190px;
  background-color: #ebf0f5;
  border-radius: 8px;
}
.authorsSection .authorPicHolder img {
  object-fit: cover;
  height: 100%;
  margin: auto;
  width: 100%;
}

.authorsListGrid .authorPicHolder {
  height: 260px;
  background-color: #ebf0f5;
  border-radius: 8px;
}
.authorsListGrid .authorPicHolder img {
  object-fit: cover;
  height: 100%;
  margin: auto;
  width: 100%;
}

.pearsSearch {
  background: #E5F1FA url("../../images/peersListBg.svg") no-repeat right;
  background-size: auto;
}

.ophthalmicPrecision .carousel .control-dots {
  align-items: end !important;
  bottom: 24px !important;
  margin: 0 !important;
}

@media only screen and (max-width: 355px) {
  .authorsListSection .authorsListGrid .authorPicHolder.author {
    height: auto !important;
  }
}
@media only screen and (max-width: 380px) {
  .card .thumbnail .actionsHolder {
    gap: 8px;
  }
  .card .thumbnail .actionsHolder svg {
    height: 14px;
    width: 16px;
  }
  .searchResutsVideos .cards .card .thumbnail {
    height: auto;
  }
  .authorsListSection .authorsListGrid .authorPicHolder {
    height: 200px !important;
  }
}
@media only screen and (max-width: 420px) {
  .exploreBtn, .talkToUs, a.button, .continueBtn, section.talktoUs .btn {
    width: 100%;
    min-width: 100%;
  }
  .videosList .cards .card {
    width: calc(90% - 40px) !important;
  }
  .videosList .cards .card .thumbnail {
    height: 160px;
  }
  .pearsSearch {
    background-size: contain;
  }
  .loginSection {
    width: 100% !important;
  }
  .loginFormHolder .loginSection {
    max-width: 100% !important;
  }
  .notificationHolder {
    position: fixed;
    top: 0;
    margin-top: 0;
    max-height: 100vh;
    width: 100%;
    border-radius: 0;
  }
  .searchResutsVideos .cards {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)) !important;
  }
  .videoSlider .carousel ul.control-dots {
    margin-top: 0;
    gap: 16px;
    bottom: 0 !important;
  }
  .videoSlider .carousel ul.control-dots li {
    min-height: 12px;
    min-width: 12px;
  }
  .videoSlider div.vjs-control-bar {
    bottom: 0px !important;
  }
  .ophthalmicPrecision .carousel ul.control-dots {
    gap: 16px;
  }
  .ophthalmicPrecision .carousel ul.control-dots li {
    min-height: 12px;
    min-width: 12px;
  }
  .authorsListGrid .authorPicHolder img {
    object-fit: cover;
    height: 100%;
    margin: auto;
    width: 100%;
  }
  .stepperBar > div:first-child > div {
    gap: 12px !important;
  }
}
@media only screen and (max-width: 430px) {
  .cropImageHolder canvas {
    width: 100% !important;
    height: auto !important;
  }
}
@media only screen and (min-width: 420px) and (max-width: 575px) {
  .searchResutsVideos .cards {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr)) !important;
  }
}
@media only screen and (max-width: 550px) {
  .authorAndActions {
    flex-wrap: wrap !important;
    flex-direction: column-reverse;
    align-items: self-start;
  }
  .authorAndActions > div {
    width: 100%;
  }
  .authorAndActions .channelInfo {
    padding: 16px 0;
    border-top: 1px solid #CECECE;
    border-bottom: 1px solid #CECECE;
  }
  #profile .responsiveGrid {
    display: grid !important;
    grid-template-columns: 1fr !important;
  }
}
@media only screen and (min-width: 630px) {
  .videoSlider .vjs-control-bar {
    bottom: 48px !important;
  }
}
@media only screen and (max-width: 630px) {
  .authorsListGrid .authorPicHolder {
    height: 220px;
  }
  .authorsListGrid .authorPicHolder img {
    object-fit: cover;
    height: 100%;
    margin: auto;
    width: 100%;
  }
}
@media only screen and (max-width: 640px) {
  .footerContentHolder > div:first-child, .termsAndConditionsHlder > div:first-child {
    font-size: 18px !important;
    margin-bottom: 8px !important;
  }
  .footerContentHolder h2, .termsAndConditionsHlder h2 {
    font-size: 20px !important;
    line-height: 28px !important;
    letter-spacing: -0.25px;
    max-width: 100% !important;
  }
  .footerContentHolder p, .termsAndConditionsHlder p {
    margin-bottom: 16px !important;
    font-size: 16px !important;
    max-width: 100% !important;
  }
  .carousel .slider-wrapper > ul li > div img {
    object-fit: cover !important;
  }
  .sliders::after {
    background-size: cover !important;
  }
  .loginFormHolder .loginSection, .thanksMsgHolder .loginSection, .successMsgBgHolder .loginSection, .registerBgHolder .loginSection, .contactUsFormBgHolder .loginSection {
    padding: 40px !important;
  }
}
@media only screen and (min-width: 768px) {
  .videosList .cards .card {
    width: calc(65% - 40px) !important;
    max-width: 372px;
  }
  .videosList .cards .card .thumbnail {
    height: 206px;
  }
}
@media only screen and (min-width: 768px) {
  .videosList .cards .card {
    width: calc(65% - 40px) !important;
    max-width: 372px;
  }
  .videosList .cards .card .thumbnail {
    height: 206px;
  }
}
@media only screen and (max-width: 767px) {
  .ophthalmicPrecision {
    background-size: cover;
  }
  .pearsSearch {
    background-size: contain;
  }
  .videoSlider .carousel .control-dots {
    bottom: 10px !important;
  }
  .videoSlider .vjs-control-bar {
    bottom: 10px !important;
  }
  .authorsSection .authorPicHolder {
    width: 140px;
    height: 150px;
  }
  .authorsSection .authorPicHolder img {
    object-fit: cover;
    height: 100%;
    margin: auto;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .searchResutsVideos .cards {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr)) !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 855px) {
  .searchResutsVideos .cards {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr)) !important;
  }
}
@media only screen and (min-width: 1023px) {
  main section.section:nth-child(2) .VideosSection {
    padding-top: 56px;
  }
  .footerContentHolder > * {
    max-width: 80%;
  }
  .footerContentHolder > .parentDisable {
    max-width: 100% !important;
  }
}
@media only screen and (max-width: 1023px) {
  main section.section:nth-child(2) .VideosSection {
    padding-top: 40px;
  }
  .responsiveGrid {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(47%, 1fr)) !important;
  }
  .videoSlider .carousel .control-dots, .ophthalmicPrecision .carousel .control-dots {
    left: 20px;
  }
  .footerContentHolder ul, .footerContentHolder ol, .termsAndConditionsHlder ul, .termsAndConditionsHlder ol,
  .exploreVideosSection .columnTwo ul,
  .exploreVideosSection .columnTwo ol,
  .authorColumns .authorInformation ul,
  .authorColumns .authorInformation ol {
    margin-left: 20px;
  }
  .footerContentHolder ul li, .footerContentHolder ol li, .termsAndConditionsHlder ul li, .termsAndConditionsHlder ol li,
  .exploreVideosSection .columnTwo ul li,
  .exploreVideosSection .columnTwo ol li,
  .authorColumns .authorInformation ul li,
  .authorColumns .authorInformation ol li {
    padding-left: 8px;
  }
  .footerContentHolder > * {
    max-width: 100%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1241px) {
  .searchResutsVideos .cards {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr)) !important;
  }
}
@media only screen and (min-width: 1242px) and (max-width: 1323px) {
  .searchResutsVideos .cards {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr)) !important;
  }
}
@media only screen and (min-width: 1024px) {
  .authorsListGrid {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr)) !important;
  }
}
@media only screen and (min-width: 630px) and (max-width: 1024px) {
  .authorsListGrid {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(185px, 1fr)) !important;
  }
  .ophthalmicPrecision .sliders.contentOne::after, .ophthalmicPrecision .sliders.contentTwo::after, .ophthalmicPrecision .sliders.contentThree::after, .ophthalmicPrecision .sliders.contentFour::after {
    width: 150% !important;
  }
}
@media only screen and (min-width: 320px) {
  .authorsListGrid {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}
@media only screen and (max-width: 1024px) {
  .specializationSection ::after {
    display: none;
  }
  .catagoryWrapper {
    position: relative;
  }
  .catagoryWrapper::before {
    content: "";
    position: absolute;
    height: 32px;
    width: 32px;
    background: linear-gradient(to right, #fff 10%, rgba(255, 255, 255, 0) 80%);
    left: 0;
  }
  .catagoryWrapper::after {
    content: "";
    position: absolute;
    height: 32px;
    width: 32px;
    background: linear-gradient(to left, #fff 10%, rgba(255, 255, 255, 0) 80%);
    right: -1px;
  }
}
.carousel .control-dots {
  display: flex;
  align-items: center;
  gap: 20px;
  bottom: 48px !important;
  left: 56px;
}
.carousel .control-dots li {
  min-height: 16px;
  min-width: 16px;
  background-color: transparent !important;
  border: 1px solid #fff !important;
  border-radius: 100%;
  margin: 0 !important;
  opacity: 1 !important;
}
.carousel .control-dots li.selected {
  background-color: #fff !important;
}

.error-text {
  color: #b40e0e;
  font-weight: normal;
  font-family: "robotoNormal";
  font-size: 12px;
}

.parentDisable {
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(210, 208, 208);
  opacity: 0.8;
  z-index: 998;
  height: 100%;
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.notificationHolder .parentDisable {
  position: absolute;
}

.captchaHolder * {
  width: 100% !important;
}
.captchaHolder * iframe > * {
  width: 100% !important;
}

canvas {
  touch-action: auto !important;
  margin-left: auto;
  margin-right: auto;
  width: 320px !important;
  height: 320px !important;
}

.grecaptcha-badge {
  z-index: 9999999 !important;
}

.socialMediaIcons > button {
  transition: all ease-in-out 300ms;
}
.socialMediaIcons > button:hover {
  transform: scale(1.5);
}

.searchResutsVideos .cards {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(372px, 1fr));
}

div.notification-detail-holder.detailsPage div.notificationHolder {
  position: static;
  height: 100%;
  width: 100% !important;
  overflow: auto;
  box-shadow: none;
}
div.notification-detail-holder.detailsPage div.notificationHolder .notificationHeader {
  display: none;
}
div.notification-detail-holder.detailsPage div.notificationHolder .notificationBody {
  margin-top: 0;
}
div.notification-detail-holder.detailsPage div.notificationHolder .notificationBody .notificationItem:hover {
  background-color: #ebf0f5;
}
div.notification-detail-holder.detailsPage div.notificationHolder .notificationBody .notificationItem.active {
  border-right: 4px solid #7DA1C4;
  background-color: #ebf0f5;
}
div.notification-detail-holder.detailsPage div.notificationHolder .notificationBody .notificationItem .updateMsgIcon {
  padding-left: 16px;
}
div.notification-detail-holder.detailsPage div.notificationHolder .notificationBody .notificationItem .msg {
  padding-right: 16px;
}
div.notification-detail-holder.detailsPage div.notificationHolder .notificationBody .notificationItem .unReadMsg {
  padding-bottom: 12px;
}
div.notification-detail-holder.detailsPage div.notificationHolder > div {
  padding: 0;
}

.notificationHolder .notificationItem:last-child .msg {
  border-bottom: 0;
}
.notificationHolder .notificationItem:first-child .msg {
  border-top: 1px solid #E4E8F0;
}

.specializationSection div.active {
  background-color: #7DA1C4;
  color: #fff;
}

/* Style for the popup */
.popup {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 100; /* Ensure popup is above overlay */
}

/* Style for the close button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.overlay-popup {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent black overlay */
  z-index: 50; /* Ensure overlay is above other elements */
}

/* Additional styles for the popup content */
.popup-content {
  color: #333;
}

#profile .responsiveGrid {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
}
#profile .profileTabs label.active {
  color: #0057B8;
  border-bottom: 3px solid #0057B8;
  font-family: robotoBold;
}

.toggle {
  cursor: pointer;
  display: inline-block;
}

.toggle-switch {
  display: inline-block;
  background: #DBE0E7;
  border-radius: 16px;
  width: 36px;
  height: 20px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}
.toggle-switch:before, .toggle-switch:after {
  content: "";
}
.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 2px;
  left: 4px;
  transition: left 0.25s;
}
.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
}
.toggle-checkbox:checked + .toggle-switch {
  background: #0057B8;
}
.toggle-checkbox:checked + .toggle-switch:before {
  left: 18px;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
  border: none;
}
.toggle-checkbox:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.toggle-label {
  margin-left: 5px;
  position: relative;
  top: 2px;
}

.videosHolder > * {
  height: 100%;
}
.videosHolder .carousel.carousel-slider,
.videosHolder .carousel .slider-wrapper {
  height: 100%;
}
.videosHolder .carousel.carousel-slider > ul,
.videosHolder .carousel .slider-wrapper > ul {
  height: 100%;
}
.videosHolder .carousel.carousel-slider > ul li > div,
.videosHolder .carousel .slider-wrapper > ul li > div {
  height: 100%;
}
.videosHolder .carousel.carousel-slider > ul li > div img,
.videosHolder .carousel .slider-wrapper > ul li > div img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.videosHolder .carousel.carousel-slider > ul li > div > div,
.videosHolder .carousel .slider-wrapper > ul li > div > div {
  height: 100%;
  padding-top: 0;
}
.videosHolder .carousel.carousel-slider > ul li .imageOverlay::before,
.videosHolder .carousel .slider-wrapper > ul li .imageOverlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
  transition: opacity 0.3s ease; /* Smooth transition effect */
  border-radius: 8px 8px 0 0;
}
.videosHolder .carousel.carousel-slider .controlsHide button.vjs-mute-control, .videosHolder .carousel.carousel-slider .controlsHide button.vjs-play-control,
.videosHolder .carousel .slider-wrapper .controlsHide button.vjs-mute-control,
.videosHolder .carousel .slider-wrapper .controlsHide button.vjs-play-control {
  display: none !important;
}
.videosHolder .carousel .control-dots {
  height: auto !important;
}
.videosHolder .vjs-limit-overlay {
  z-index: 99 !important;
}
.videosHolder span a#clickHereLink {
  white-space: pre-wrap;
}

.bannerVideos .videosHolder video {
  position: static !important;
}

.exploreVideosSection .columnOne > div {
  height: 100%;
}
.exploreVideosSection .columnOne .video-js {
  height: 100% !important;
  padding-top: 0;
}

video::-webkit-media-controls {
  display: none;
}

.video-js .vjs-auto-mute {
  display: none !important;
}

.footerContentHolder > div:first-child {
  font-family: barlowBold;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.25px;
  margin-bottom: 24px;
}
.footerContentHolder > div:nth-child(2) h2 + p {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.25px;
}
.footerContentHolder :where(h1, h2, h3, h4, h5, h6) {
  margin-bottom: 20px;
  font-family: barlowMedium;
  max-width: 80%;
  color: #333;
}
.footerContentHolder h2 {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.25px;
}
.footerContentHolder h3 {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.25px;
}
.footerContentHolder p {
  margin-bottom: 16px;
  color: #333;
  font-weight: normal;
  font-size: 16px;
}

.termsAndConditionsHlder h2 {
  color: #333;
  font-family: barlowBold;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.25px;
  margin-bottom: 24px;
  max-width: 80%;
}
.termsAndConditionsHlder h3 {
  margin-bottom: 24px;
}
.termsAndConditionsHlder p {
  margin-bottom: 20px;
  color: #333;
  font-weight: normal;
  font-size: 18px;
  max-width: 80%;
}

.stepperBar > div:first-child > div {
  display: flex !important;
  width: 100% !important;
  gap: 24px;
  justify-content: center;
  max-width: 70%;
}
.stepperBar > div:first-child > div > div {
  width: 33.33% !important;
}
.stepperBar > div:first-child > div > div > div {
  width: 100% !important;
  height: 12px !important;
  border-radius: 50px !important;
  background-color: #717375;
  position: relative;
  cursor: pointer;
}
.stepperBar > div:first-child > div > div > div span, .stepperBar > div:first-child > div > div > div a {
  position: absolute;
  inset: 0;
  z-index: 50;
  opacity: 0;
}
.stepperBar > div:first-child > div > div > div ~ div {
  display: none !important;
}
.stepperBar > div:first-child > div > div > div.active {
  background-color: #0057B8 !important;
}

#cookiescript_injected {
  width: 300px !important;
  left: 20px !important;
  bottom: 20px !important;
  padding: 15px 20px !important;
  border-radius: 10px;
  background-color: rgba(45, 45, 45, 0.95) !important;
}
#cookiescript_injected #cookiescript_toppart {
  display: flex;
  flex-direction: column;
}
#cookiescript_injected #cookiescript_toppart > div {
  max-width: 100%;
}
#cookiescript_injected #cookiescript_toppart #cookiescript_checkboxs {
  gap: 10px;
  margin-bottom: 12px !important;
  flex-direction: column;
}
#cookiescript_injected #cookiescript_toppart #cookiescript_checkboxs span.cookiescript_checkbox_text {
  font-size: 11px;
}
#cookiescript_injected #cookiescript_toppart #cookiescript_checkboxs .cookiescript_checkbox_label {
  margin: 0 !important;
}
#cookiescript_injected #cookiescript_toppart #cookiescript_checkboxs .mdc-checkbox {
  padding: 0 8px;
}
#cookiescript_injected #cookiescript_toppart #cookiescript_checkboxs .mdc-checkbox .mdc-checkbox__background {
  top: 0 !important;
}
#cookiescript_injected #cookiescript_toppart #cookiescript_checkboxs .cookiescript_checkbox {
  align-items: center;
}
#cookiescript_injected #cookiescript_toppart #cookiescript_description {
  margin-bottom: 12px;
}
#cookiescript_injected #cookiescript_manage_wrap #cookiescript_manage {
  display: block;
  text-align: center;
  margin: 4px 0;
}

.relatedVideos .videos .video:last-child {
  margin-bottom: 4px;
}

.loginFormHolder, .thanksMsgHolder, .successMsgBgHolder, .registerBgHolder, .contactUsFormBgHolder, .resetPasswordBg, .userActivationBg, .succesMsgHolderBg, .gdprConsentHolderBg, .specializationSection {
  background-image: url(../../images/modalBg.svg);
  background-size: cover;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 140px;
}
.loginFormHolder .loginSection, .thanksMsgHolder .loginSection, .successMsgBgHolder .loginSection, .registerBgHolder .loginSection, .contactUsFormBgHolder .loginSection, .resetPasswordBg .loginSection, .userActivationBg .loginSection, .succesMsgHolderBg .loginSection, .gdprConsentHolderBg .loginSection, .specializationSection .loginSection {
  padding: 40px 60px;
  position: static;
  z-index: 99;
}

.userProfile ul li:hover span {
  color: #0057B8;
}
.userProfile ul li:hover span svg path {
  fill: #0057B8;
}
.userProfile ul li.support:hover span svg rect, .userProfile ul li.support:hover span svg circle {
  fill: #0057B8;
}
.userProfile ul li.support:hover span svg path {
  fill: transparent;
  stroke: #0057B8;
}
.userProfile ul li.preferences:hover span svg rect, .userProfile ul li.preferences:hover span svg circle {
  fill: #0057B8;
}
.userProfile ul li.preferences:hover span svg path {
  fill: transparent;
  stroke: #0057B8;
}
.userProfile ul li.active span {
  color: #0057B8;
}
.userProfile ul li.active span svg path {
  fill: #0057B8;
}
.userProfile ul li.active.support span svg rect, .userProfile ul li.active.support span svg circle {
  fill: #0057B8;
}
.userProfile ul li.active.support span svg path {
  fill: transparent;
  stroke: #0057B8;
}
.userProfile ul li.active.preferences span svg rect, .userProfile ul li.active.preferences span svg circle {
  fill: #0057B8;
}
.userProfile ul li.active.preferences span svg path {
  fill: transparent;
  stroke: #0057B8;
}

.ratingsHolder .react-simple-star-rating span.filled-icons {
  color: #034b9b !important;
}

.cards .card .description .videoCatagory {
  display: none !important;
}

.min-h-screen {
  min-height: 100vh;
}

.line-clamp-7 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
}

.line-clamp-8 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
}

.line-clamp-9 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9;
}

.line-clamp-10 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
}

.line-clamp-11 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 11;
}

.line-clamp-12 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 12;
}

.line-clamp-13 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 13;
}

.line-clamp-14 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 14;
}

.line-clamp-15 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 15;
}

.line-clamp-16 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 16;
}

.line-clamp-17 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 17;
}

.line-clamp-18 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 18;
}

.line-clamp-19 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 19;
}

.line-clamp-20 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 20;
}

.ophthalmicPrecision {
  position: relative;
  padding: 0;
}
.ophthalmicPrecision * {
  height: 100%;
  width: 100%;
}
.ophthalmicPrecision .sliders {
  display: flex;
  align-items: center;
}
.ophthalmicPrecision .sliders.contentOne::after {
  content: "";
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: -1;
  background-image: url("../../images/Slider/one.svg");
}
.ophthalmicPrecision .sliders.contentTwo::after {
  content: "";
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: -1;
  background-image: url("../../images/Slider/two.svg");
}
.ophthalmicPrecision .sliders.contentThree::after {
  content: "";
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: -1;
  background-image: url("../../images/Slider/three.svg");
}
.ophthalmicPrecision .sliders.contentFour::after {
  content: "";
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: -1;
  background-image: url("../../images/Slider/four.svg");
}

.footerContentHolder a, .termsAndConditionsHlder a,
.exploreVideosSection .columnTwo a,
.authorColumns .authorInformation a {
  text-decoration: underline;
  color: #0057B8;
}
.footerContentHolder ul, .termsAndConditionsHlder ul,
.exploreVideosSection .columnTwo ul,
.authorColumns .authorInformation ul {
  list-style-type: disc !important;
}
.footerContentHolder ul ul, .termsAndConditionsHlder ul ul,
.exploreVideosSection .columnTwo ul ul,
.authorColumns .authorInformation ul ul {
  list-style-type: circle !important;
  margin-top: 10px;
  margin-left: 20px;
}
.footerContentHolder ul ul ul, .termsAndConditionsHlder ul ul ul,
.exploreVideosSection .columnTwo ul ul ul,
.authorColumns .authorInformation ul ul ul {
  list-style-type: square !important;
  margin-top: 10px;
  margin-left: 20px;
}
.footerContentHolder ol, .termsAndConditionsHlder ol,
.exploreVideosSection .columnTwo ol,
.authorColumns .authorInformation ol {
  list-style-type: decimal !important;
}
.footerContentHolder ol ol, .termsAndConditionsHlder ol ol,
.exploreVideosSection .columnTwo ol ol,
.authorColumns .authorInformation ol ol {
  list-style-type: lower-alpha !important;
  margin-top: 10px;
  margin-left: 20px;
}
.footerContentHolder ol ol ol, .termsAndConditionsHlder ol ol ol,
.exploreVideosSection .columnTwo ol ol ol,
.authorColumns .authorInformation ol ol ol {
  list-style-type: lower-roman !important;
  margin-top: 10px;
  margin-left: 20px;
}
.footerContentHolder ul, .footerContentHolder ol, .termsAndConditionsHlder ul, .termsAndConditionsHlder ol,
.exploreVideosSection .columnTwo ul,
.exploreVideosSection .columnTwo ol,
.authorColumns .authorInformation ul,
.authorColumns .authorInformation ol {
  margin-left: 24px;
}
.footerContentHolder ul li, .footerContentHolder ol li, .termsAndConditionsHlder ul li, .termsAndConditionsHlder ol li,
.exploreVideosSection .columnTwo ul li,
.exploreVideosSection .columnTwo ol li,
.authorColumns .authorInformation ul li,
.authorColumns .authorInformation ol li {
  padding-left: 8px;
  margin-bottom: 8px;
}
.footerContentHolder ul li:last-child, .footerContentHolder ol li:last-child, .termsAndConditionsHlder ul li:last-child, .termsAndConditionsHlder ol li:last-child,
.exploreVideosSection .columnTwo ul li:last-child,
.exploreVideosSection .columnTwo ol li:last-child,
.authorColumns .authorInformation ul li:last-child,
.authorColumns .authorInformation ol li:last-child {
  margin-bottom: 20px;
}

body.resetPassword header, body.resetPassword footer {
  display: none;
}
body.resetPassword main {
  background-color: #F4F8FD;
}

