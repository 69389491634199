@mixin margin {
    margin-top: 10px;
    margin-left: 20px;
}
.footerContentHolder, .termsAndConditionsHlder,
.exploreVideosSection .columnTwo,
.authorColumns .authorInformation  {
    a{
        text-decoration: underline;
        color: #0057B8;
    }
    ul{
        list-style-type: disc !important;
        ul{
            list-style-type: circle !important;
            @include margin;
            ul{
                list-style-type: square !important;
                @include margin;
            }
        }
    }
    ol{
        list-style-type: decimal !important;
        
        ol{
            list-style-type: lower-alpha !important;
            @include margin;
            ol{
                list-style-type: lower-roman !important;
                @include margin;
        
            }    
        }
    }
    ul , ol{
        margin-left: 24px;
        li{
            padding-left: 8px;
            margin-bottom:8px;
            &:last-child{
                margin-bottom: 20px;
            }       
        }
    }
}