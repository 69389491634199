.carousel{
    .control-dots{
        display: flex;
        align-items: center;
        gap: 20px;
        bottom: 48px !important;
        left: 56px;
        li{
            min-height: 16px;
            min-width: 16px;
            background-color: transparent !important;
            border: 1px solid #fff !important;
            border-radius: 100%;
            margin: 0 !important;
            opacity: 1 !important;
            &.selected{
                background-color: #fff !important;
            }   
        }
    }
}
.error-text {
    color: #b40e0e;
    font-weight: normal;
    font-family: "robotoNormal";
    font-size: 12px;
}

.parentDisable {
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(210, 208, 208);
    opacity: 0.8;
    z-index: 998;
    height: 100%;
    width: 100%;

    // new styles
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.notificationHolder {
    .parentDisable{
        position: absolute;
    }
}

// captcha css
.captchaHolder *{
    width: 100% !important;
    iframe {
        > *{
            width: 100% !important;
        }
    }
}
//canvas style
canvas{
    touch-action: auto !important;
    margin-left: auto;
    margin-right: auto;
    width: 320px !important;
    height: 320px !important;
}

//captcha style
.grecaptcha-badge{
    z-index: 9999999 !important;
}

//social media share icons
.socialMediaIcons{
    > button{
        transition: all ease-in-out 300ms;
        &:hover{
            transform: scale(1.5);
        }
    }
}

