
.cards{
    .card{
        .description {
            .videoCatagory {
                display: none !important;
            }
        }
    }
}
