
/* roboto-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'robotoLight';
    font-style: normal;
    font-weight: 300;
    src: url('../../fonts/roboto/roboto-v30-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'robotoNormal';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/roboto/roboto-v30-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-500 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'robotoMedium';
    font-style: normal;
    font-weight: 500;
    src: url('../../fonts/roboto/roboto-v30-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'robotoBold';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/roboto/roboto-v30-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-900 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'robotoExtrabold';
    font-style: normal;
    font-weight: 900;
    src: url('../../fonts/roboto/roboto-v30-latin-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}


// balow fonts

/* barlow-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'barlowNormal';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/barlow/barlow-v12-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* barlow-medium - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'barlowMedium';
    font-style: normal;
    font-weight: 500;
    src: url('../../fonts/barlow/barlow-v12-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
  
  /* barlow-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'barlowBold';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/barlow/barlow-v12-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

  
  
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: #BDCDD6;
    border-radius: 8px;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #888;
    cursor: pointer;
}

// removing scroll bar in webkite browsers
.cards::-webkit-scrollbar,
.catagoryHolder::-webkit-scrollbar,
.catagoryWrapper::-webkit-scrollbar,
.authors::-webkit-scrollbar,
.subCatagoryHolder::-webkit-scrollbar,
.formWrapper::-webkit-scrollbar{
    display: none;
}

// removing scroll bar in firefox browser
.cards,
.catagoryHolder,
.authors,
.catagoryWrapper,
.authors,
.subCatagoryHolder,
.formWrapper{
    scrollbar-width: none;
    -ms-overflow-style: none;
} 
@supports (-moz-appearance:none) {
    * {
        scrollbar-width: thin; /* "thin" or "auto" */
    }
}



// classes for fonts
body{
    font-family:robotoNormal;
    font-weight: normal;
}

.font-light{
    font-family: robotoLight;
}
.font-normal{
    font-family: robotoNormal;
}
.font-medium{
    font-family: robotoMedium;
}
.font-bold{
    font-family: robotoBold;
}
.font-extrabold{
    font-family: robotoExtrabold;
}

.barlowNormal{
    font-family: barlowNormal;
}
.barlowBold{
    font-family: barlowBold;
}
.barlowMedium{
    font-family: barlowMedium;
}


// disabling outline for input fields
input:focus, textarea:focus{
    outline: none;
    box-shadow: none;
}
textarea {
    resize: none;
}

// hover action for buttons
.btn:hover{
    background-color: #034b9b;
}

// disabled buttons
.disabled{
    opacity: 0.3;
    cursor: none;
    pointer-events: none;
}
// button.disabled{
//     background-color: #B5CDEA;
//     opacity: 1;
// }

//video rounded
.videosHolder {
    video{
        background-color: #006FBA;
    }
}
a{
    color: #0057B8;
}
.supportFormHolder{
    video{
        clip-path: inset(1px 1px);
    }
}
.doctorsName{
    > span{
        &:last-child{
            span.pipe{
                display: none;
            }
        }
    }
}



