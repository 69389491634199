#profile{
    .responsiveGrid{
        display: grid !important;
        grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    }
    .profileTabs{
        label.active{
            color:#0057B8;
            border-bottom: 3px solid #0057B8;
            font-family: robotoBold;
        }
    }
}