#cookiescript_injected{
    width: 300px !important;
    left:20px !important;
    bottom: 20px !important;
    padding: 15px 20px !important;
    border-radius: 10px;
    background-color: rgba(45, 45, 45, 0.95) !important;
    #cookiescript_toppart{
        display: flex;
        flex-direction: column;
        > div{
            max-width: 100%;
        }
        #cookiescript_checkboxs{
            span.cookiescript_checkbox_text{
                font-size: 11px;
            }
            .cookiescript_checkbox_label{
                margin: 0 !important;
            }
            .mdc-checkbox{
                padding: 0 8px;
                .mdc-checkbox__background{
                    top: 0 !important;
                }
            }
            gap: 10px;
            margin-bottom: 12px !important;
            flex-direction: column;
            .cookiescript_checkbox{
                align-items: center;
            }
        }
        #cookiescript_description{
            margin-bottom: 12px;
        }
    }
    #cookiescript_manage_wrap{
        #cookiescript_manage{
            display: block;
            text-align: center;
            margin: 4px 0;
        }
    }
}