.toggle {
	cursor: pointer;
	display: inline-block;
}
.toggle-switch {
	display: inline-block;
	background: #DBE0E7;
	border-radius: 16px;
	width: 36px;
	height: 20px;
	position: relative;
	vertical-align: middle;
	transition: background 0.25s;
	&:before,
	&:after {
		content: "";
	}
	&:before {
		display: block;
		background: linear-gradient(to bottom, #fff 0%,#eee 100%);
		border-radius: 50%;
		width: 16px;
		height: 16px;
		position: absolute;
		top: 2px;
		left: 4px;
		transition: left 0.25s;
	}
	.toggle:hover &:before {
		background: linear-gradient(to bottom, #fff 0%,#fff 100%);
	}
	.toggle-checkbox:checked + & {
		background: #0057B8;
		&:before {
			left: 18px;
		}
	}
}
.toggle-checkbox {
	position: absolute;
	visibility: hidden;
    border: none;
    &:focus{
        outline: none;
        border: none;
        box-shadow: none;
    }
}
.toggle-label {
	margin-left: 5px;
	position: relative;
	top: 2px;
}